import { ElementType, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { IoSettingsOutline } from 'react-icons/io5'
import { HiHome, HiMiniBuildingOffice2 } from 'react-icons/hi2'
import { RiBodyScanLine } from 'react-icons/ri'
import { CUSTOMERS_ROUTE, DASHBOARD_ROUTE, LOGIN_ROUTE, QUICK_SIZING_ROUTE, SCANS_ROUTE, SETTINGS_ROUTE, TENANTS_ROUTE } from 'routes/path'
import { useAppDispatch, useAppSelector } from 'store'
import { logout } from 'store/auth'
import { Logo } from 'assets/png'
import { getServicesVersion } from 'store/servicesVersion'
import { getTenants } from '../store/tenant'

interface Props {
    children: React.ReactNode
}

export const NavigationBar = ({ children }: Props) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { servicesVersion, isLoading } = useAppSelector((state) => state.servicesVersion)

    const activeStyle = 'flex font-bold text-blue-600 border-blue-500 border-l-4 bg-gray-50'
    const inactiveStyle = 'flex text-gray-800'

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }

    useEffect(() => {
        dispatch(getServicesVersion())
    }, [dispatch])

    const renderServiceStatus = (service: string, version: string | undefined) => (
        <div>
            {service}: {isLoading ? 'Loading...' : version ? <>{version} 🟢</> : <>offline 🔴</>}
        </div>
    )

    const getIcon = (Icon: ElementType) => {
        return <Icon className="h-5 w-5 ml-3 text-gray-400 self-center" />
    }

    const navLinks = [
        { label: 'Dashboard', path: DASHBOARD_ROUTE, icon: getIcon(HiHome) },
        { label: 'Businesses', path: TENANTS_ROUTE, icon: getIcon(HiMiniBuildingOffice2) },
        { label: 'Scans', path: SCANS_ROUTE, icon: getIcon(RiBodyScanLine) },
        { label: 'Quick Sizing', path: QUICK_SIZING_ROUTE, icon: getIcon(RiBodyScanLine) },
        { label: 'Customers', path: CUSTOMERS_ROUTE, icon: getIcon(RiBodyScanLine) },
        { label: 'Settings', path: SETTINGS_ROUTE, icon: getIcon(IoSettingsOutline) },
    ]

    return (
        <div className="flex h-screen bg-gray-50">
            <aside className={`md:w-64 bg-white ${isMobileMenuOpen ? 'block' : 'hidden'} md:block md:fixed md:h-screen`}>
                <div className="flex items-center justify-center h-16">
                    <img className="mx-auto" src={Logo} alt="Logo" width={'50%'} />
                </div>
                <nav className="flex flex-col py-4 mt-8">
                    {navLinks.map((link, index) => (
                        <NavLink key={index} to={link.path} className={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}>
                            <div className="flex py-3 px-4">
                                <div>{link.icon}</div>
                                <p className="pl-4 hover:text-black text-sm">{link.label}</p>
                            </div>
                        </NavLink>
                    ))}
                </nav>{' '}
                <div className="absolute bottom-4 left-4 text-xs text-gray-500">
                    Dashboard: {process.env.REACT_APP_VERSION} 🟢
                    <br />
                    {renderServiceStatus('Engine', servicesVersion?.engine)}
                    {renderServiceStatus('Quick Sizer', servicesVersion?.quicksizer)}
                    {renderServiceStatus('Pose Detector', servicesVersion?.pose_detection)}
                    {renderServiceStatus('Segmentation', servicesVersion?.segmentation)}
                    {renderServiceStatus('SAM-Segmentation', servicesVersion?.gsam)}
                    {renderServiceStatus('Preprocessor', servicesVersion?.preprocessor)}
                    {renderServiceStatus('Reconstruction', servicesVersion?.reconstruction)}
                </div>
            </aside>

            <main className="flex-1 overflow-x-hidden overflow-y-auto ml-0 md:ml-64">
                <div className="md:hidden">
                    <button onClick={toggleMobileMenu} className="p-4 text-gray-800 hover:text-gray-900">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                </div>

                <div className="w-full  justify-end bg-white h-16 px-4 hidden md:grid">
                    <div
                        className="align-self-center cursor-pointer"
                        onClick={() => {
                            dispatch(logout())
                            navigate(LOGIN_ROUTE)
                        }}
                    >
                        Logout
                    </div>
                </div>

                <div className="mt-12 p-4">{children}</div>
            </main>
        </div>
    )
}
